import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layouts";

// Services
import UtilsService from "../services/utils.service";

// markup
const NotFoundPage = (props) => {
  return (
      <Layout country={UtilsService.getLocaleFromUrl().country} locale="sv"  path={props.location}>
        <div className="cd-404-page">
          <div className="cd-max-width">
            <h1>404</h1>
            <p>Sidan hittades inte</p>
            <Link to={`/sv`} >Till startsidan</Link>
          </div>
        </div>
      </Layout>
  )
}

export default NotFoundPage
